import './App.css';
import Home_page from './Home_page/Home_page';
import FontPicker from 'font-picker-react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Add_event_page from './Add_Event_Page/Add_event_page';
import Personal_page from './Personal_page/Personal_page';
import Event_page from './Event_page/Event_page';
import Gallery_page from './Gallery_page/Gallery_page';
import Edit_event from './Edit_Event_page/Edit_event';
import UploadBox from './UploadBox/UploadBox';
import { useState } from 'react';
import Add_event_small from './Add_Event_Page/Add_event_small';
import TermsOfService from './Add_Event_Page/Section_3/TermsOfService';
import File_Editor_Page from './File_Editor_Page/File_Editor_Page';

function App() {
  const [isUploading, setIsUploading] = useState(false);
  const [localArray, setLocalArray] = useState([]);
  const handleSetLocalArray = async (id, isAdd) => {
    setLocalArray((prevArray) => {
      const newArray = isAdd
        ? [...prevArray, id] // Add the new ID to the array
        : prevArray.filter((item) => item !== id); // Remove the ID from the array

      return newArray;
    });
  };
  return (
    <Router>
      <div className='app_page'>
        <Switch>
          <Route path='/' exact>
            <Home_page></Home_page>
          </Route>
          <Route path='/Add_Event' exact>
            {window.innerWidth < 900 ? (
              <Add_event_small></Add_event_small>
            ) : (
              <Add_event_page></Add_event_page>
            )}
          </Route>
          <Route path='/Edit_event/:event_id?' exact>
            <Edit_event></Edit_event>
          </Route>
          <Route path='/Personal_page' exact>
            <Personal_page></Personal_page>
          </Route>
          <Route path='/event/:event_id?' exact>
            <Event_page
              handle_uploading_progress={(value) => {
                setIsUploading(value);
              }}
              handleSetLocalArray={handleSetLocalArray}
            ></Event_page>
          </Route>
          <Route path='/gallery/:event_id?' exact>
            <Gallery_page
              localArray={localArray}
              handleSetLocalArray={handleSetLocalArray}
            ></Gallery_page>
          </Route>
          <Route path='/Terms' exact>
            <div>
              <TermsOfService isPage={true}></TermsOfService>
            </div>
          </Route>
          <Route path='/eventFiles/:event_id?' exact>
            <File_Editor_Page></File_Editor_Page>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
