import React, { useEffect, useState } from 'react';
import BackGround from '../Home_page/BackGround';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as go from 'react-icons/go';

import {
  get_data,
  get_data_by_id,
  get_data_by_query,
  get_event_by_id,
  get_events,
  sendPasswordReset,
} from '../../firebase';
import QRCode from 'react-qr-code';
import './Personal_page.css';
import Event from './Event/Event';
import Sign_in from '../Add_Event_Page/Section_3/Sign_in';
import Log_in from '../Add_Event_Page/Section_3/Log_in';
import TermsOfService from '../Add_Event_Page/Section_3/TermsOfService';
const Personal_page = () => {
  const history = useHistory();
  const location = useLocation();
  const [events, set_events] = useState([]);
  const [my_event, set_my_event] = useState([]);

  const [my_events, set_my_events] = useState([]);
  const [my_has_more, set_my_has_more] = useState(true);
  const [user_id, set_my_user_id] = useState(localStorage.getItem('user_id'));
  const [is_sign_in, set_is_sign_in] = useState(false);
  const [animation, set_animation] = useState(true);
  const [sign_in, set_sign_in] = useState(true);
  const [first_time, set_first_time] = useState(true);
  const [show_terms, set_show_terms] = useState(false);

    useEffect(() => {
    get_all_event();
  }, []);
  const [is_my_events, set_is_my_events] = useState(false);
  const get_all_event = () => {
    const keys = Object.keys(localStorage);
    const parsedData = [];

    // Parse each key and create an object

    keys
      .filter((key) => key.endsWith('_left'))
      .map(async (key) => {
        const { user_id, event_id } = {
          user_id: key.split('/')[0].split('_')[0],
          // event_id: key.split("/")[1].split("_")[0],
          event_id: key.split('/')[0].split('_')[0],
        };
        parsedData.push({ user_id, event_id });
      });
    let events_array = [];
    let promises = [];
    for (let i = 0; i < parsedData.length; i++) {
      let promise = new Promise((resolve) => {
        get_event_by_id(parsedData[i].event_id, (data) => {
          if (data) {
            events_array.push(data);
          } else {
            window.localStorage.removeItem(`${parsedData[i].event_id}_left`);
            window.localStorage.removeItem(`${parsedData[i].event_id}_user`);
          }
          resolve();
        });
      });

      promises.push(promise);
    }
    Promise.all(promises).then(() => {
      set_events(events_array.sort((a, b) => b.eventDate - a.eventDate));
    });

    // Now parsedData contains an array of objects with user_id and event_id extracted from keys
  };
  const handle_my_events = () => {
    if (user_id === null) {
      set_is_sign_in(true);
    } else {
      get_my_event(user_id);
    }
  };
  const get_my_event = (user_id) => {
    set_is_my_events(true);
    if (my_has_more)
      get_data_by_query('event', 'user_id', '==', user_id, (events) => {
        set_my_events(events.sort((a, b) => b.eventDate - a.eventDate));
        set_my_has_more(false);
      });
  };
  const filter_event = (id) => {
    set_events((prevEvents) => prevEvents.filter((event) => event.id !== id));
    set_my_events((prevMyEvents) =>
      prevMyEvents.filter((event) => event.id !== id)
    );
  };
  return (
    <div className='personal_page '>
      <BackGround></BackGround>
      <header className='personal_page_header'>
        {user_id !== null && (
          <div className='log_out_button'>
            <button
              className='nav_bar_my_events'
              onClick={() => {
                localStorage.removeItem('user_id');
                set_is_my_events(false);
                set_my_user_id(null);
              }}
            >
              <span> התנתקו</span>
            </button>
          </div>
        )}
        <div className='personal_header_footer'>
          <button
            onClick={() => set_is_my_events(false)}
            className={
              !is_my_events
                ? 'personal_header_footer_btn selected_header'
                : 'personal_header_footer_btn '
            }
          >
            <span>All events</span>
          </button>
          <button
            onClick={() => {
              handle_my_events();
            }}
            className={
              is_my_events
                ? 'personal_header_footer_btn selected_header'
                : 'personal_header_footer_btn '
            }
          >
            <span>My events</span>
          </button>
        </div>
      </header>
      <main className='personal_page_main'>
        {is_my_events
          ? my_events.map((event, index) => {
              return (
                <Event
                  key={index}
                  {...event}
                  user_id={localStorage.getItem('user_id')}
                  my_events={true}
                  filter_event={filter_event}
                ></Event>
              );
            })
          : events.map((event, index) => {
              return (
                <Event
                  key={index}
                  {...event}
                  user_id={localStorage.getItem('user_id')}
                  my_events={localStorage.getItem('user_id') === event.user_id}
                  filter_event={filter_event}
                ></Event>
              );
            })}
      </main>
      <footer className='personal_page_footer'></footer>
      {is_sign_in ? (
        <div
          className='sign_in_page'
          onClick={() => {
            set_is_sign_in(false);
          }}
        >
          <go.GoX className='barcode_page_x'></go.GoX>

          <header>
            <span>התחברו על מנת לצפות באירועים שלכם</span>
          </header>

          <div
            className={'sign_container'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Sign_in
              handle_switch={() => {
                setTimeout(() => {
                  set_sign_in(!sign_in);
                }, 500);
                set_animation(!animation);
              }}
              handle_user={(user_id) => {
                set_my_user_id(user_id);
                set_is_sign_in(false);
                get_my_event(user_id);
              }}
              handle_terms={() => set_show_terms(true)}
            ></Sign_in>
          </div>
        </div>
      ) : (
        ''
      )}
      {show_terms ? (
        <div className='terms_page'>
          <TermsOfService
            handle_terms={() => set_show_terms(false)}
          ></TermsOfService>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Personal_page;
