import React, { useEffect, useRef, useState } from 'react';
import './Add_event_small.css';
import BackGround from '../Home_page/BackGround';
import 'aos/dist/aos.css';
import AOS from 'aos';
import img1 from '../../images/img1.jpg';
import img2 from '../../images/img2.jpg';
import img3 from '../../images/img3.jpg';
import FontPicker from 'font-picker-react';
import Scene from './Phone/Scene';
import axios from 'axios';

import PinyonScript from '../../Fonts/PinyonScript-Regular.ttf';
import Tangerine from '../../Fonts/Tangerine-Regular.ttf';
import html2canvas from 'html2canvas'; // Import html2canvas
import * as Iconset from 'react-icons/hi2';

import { Ripple } from 'primereact/ripple';
import Section_1 from './Section_1/Section_1';
import Section_2 from './Section_2/Section';
import Section_3 from './Section_3/Section_3';
import Section_4 from './Section_4/Section_4';
import { useHistory } from 'react-router-dom';
import * as tb from 'react-icons/tb';
import {
  add_data,
  add_event,
  get_data,
  get_data_by_id,
  upload_bg_image,
  upload_file,
  upload_profile_image,
} from '../../firebase';
import { fonts_array } from '../../Fonts/fonts';
import Logo_page from '../Logo_page/Logo_page';
import Small_Phone from './Phone/Small_Phone';
import TermsOfService from './Section_3/TermsOfService';
import { HEADERS } from './Section_4/data';
const Add_event_small = () => {
  const history = useHistory();
  const phoneRef = useRef();

  const [animation, set_animation] = useState(false);
  const [phone_animation, set_phone_animation] = useState(false);

  const [page, set_page] = useState(1);
  const [show_terms, set_show_terms] = useState(false);

  const [button_color, set_button_color] = useState('#f5cfa2');
  const [is_dark_color, set_is_dark_color] = useState(false);
  const [is_preview, set_is_preview] = useState(false);
  const [is_name_font, set_is_name_font] = useState(false);
  const [is_date_font, set_is_date_font] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [user_id, set_user_id] = useState(localStorage.getItem('user_id'));
  const [is_set_img_bg, set_is_set_img_bg] = useState(0);
  const [dragBounds, setDragBounds] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [event_data, set_event_data] = useState({
    button_color: '#ffefd8',
    is_en: true,

    event_name: {
      name: '',
      y: 80,
      font: { name: 'PinyonScript', font: PinyonScript },
      font_size: 44,
      font_color: '#000000',
      font_bg_color: '#ffefd8',

      opacity: 80,
    },
    date: {
      name: '',
      y: 30,
      font: { name: 'Tangerine', font: Tangerine },
      font_size: 34,
      font_color: '#000000',
      font_bg_color: '#ffefd8',
      opacity: 80,
    },
    config_data: {
      guests: 10,
      photos: 5,
      price: 10,
    },
    discount: 0,

    createdAt: new Date(),
  });

  const [bg_img, set_bg_img] = useState();

  const handle_event_data = (set_value) => {
    set_event_data({ ...event_data, ...set_value });
  };

  const handle_bg_img = (bg_img) => {
    set_bg_img(bg_img);
  };
  const sendinvoice = async () => {
    try {
      get_data_by_id('user', user_id, async (data) => {
        try {
          await axios.post(
            `https://us-central1-invoice-webby.cloudfunctions.net/sendMomentsInvoice?user_id=${user_id}&name=${
              data.name ? data.name : data.email
            }&email=${data.email}&price=${
              event_data.config_data.price
            }&geusts=${event_data.config_data.guests}&photos=${
              event_data.config_data.photos
            }&discount=${event_data.discount}`,
            {}
          );
        } catch (error) {
          console.error('Error sending invoice:', error);
        }
      });
    } catch (error) {
      console.error('Error getting useer data:', error);
    }
  };
  const handle_add_event = (isFree) => {
    upload_bg_image(bg_img, 'event_image', '', 'none', (url) => {
      add_data(
        'event',
        {
          ...event_data,
          img_url: url,
          user_id,
          eventDate: event_data.eventDate.$d,
        },
        (result) => {
          localStorage.setItem(`${result}_left`, event_data.config_data.photos);
          if (!isFree) sendinvoice();
          sendDesign(url, result);

          history.push({
            pathname: `/personal_page`,
            state: { user_id: user_id },
          });
        }
      );
    });
  };
  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  const sendDesign = async (url, event_id) => {
    try {
      get_data_by_id('user', user_id, async (data) => {
        try {
          const queryParams = new URLSearchParams({
            fspace: '',
            is_dark_color: is_dark_color,
            is_en: event_data.is_en,
            photos: event_data.config_data.photos,
            button_color: event_data.button_color,
            my_image: url,
            email: data.email,
            event_id,
            header1_name: event_data.event_name.name,
            header1_y: event_data.event_name.y,
            header1_font_size: event_data.event_name.font_size,
            header1_font: event_data.event_name.font.name,
            header1_opacity: event_data.event_name.opacity,
            header1_font_color: event_data.event_name.font_color,
            header1_font_bg_color: event_data.event_name.font_bg_color,
            header2_name: event_data.date.name,
            header2_y: event_data.date.y,
            header2_font_size: event_data.date.font_size,
            header2_font: event_data.date.font.name,
            header2_opacity: event_data.date.opacity,
            header2_font_color: event_data.date.font_color,
            header2_font_bg_color: event_data.date.font_bg_color,
            space: '',
          }).toString();
          await axios.post(
            `https://us-central1-invoice-webby.cloudfunctions.net/designMail?${queryParams}}`,
            {}
          );
        } catch (error) {
          console.error('Error sending invoice:', error);
        }
      });
    } catch (error) {
      console.error('Error getting useer data:', error);
    }
  };

  return (
    <div
      className='add_event_page'
      onClick={() => {
        set_is_date_font(false);
        set_is_name_font(false);
      }}
    >
      <Logo_page></Logo_page>

      <BackGround></BackGround>

      <div
        onClick={() => {
          if (page > 1) {
            set_animation(!animation);
            set_page(page - 1);
          } else {
            history.goBack();
          }
        }}
        style={{
          position: 'fixed',
          top: '15px',
          left: '15px',
          fontSize: '35px',

          zIndex: '3',
        }}
      >
        <Iconset.HiOutlineArrowLeft className='close_icon'></Iconset.HiOutlineArrowLeft>{' '}
      </div>
      <div className='event_section_1_header'>
        <span className='header_span_1'>צרו את האירוע שלכם</span>
        <span className='header_span_2'> {HEADERS[page]}</span>
      </div>
      <div
        className={'small_phone_3d_cntainer small_phone_animation'}
        style={{
          height: page < 2 ? '40vh' : '30vh',
          transition: 'height 1s',
          transitionDelay: '0.5',
        }}
        // data-aos="fade-in"
        // data-aos-delay="1500"
      >
        <Small_Phone
          page={page}
          is_en={event_data.is_en}
          button_color={event_data.button_color}
          event_name={event_data.event_name}
          photos={event_data.config_data.photos}
          date={event_data.date}
          is_dark_color={is_dark_color}
          handle_bg_img={handle_bg_img}
          handle_event_data={handle_event_data}
          is_set_img={is_set_img_bg}
          phoneRef={phoneRef}
        ></Small_Phone>
      </div>
      <section
        className='
      event_section_page'
      >
        {localStorage.getItem('user_id') !== null ? (
          <section
            className={'small_event_section small_screen_slide_up'}
            style={{
              height: page < 2 ? '35vh' : '50vh',
              transition: 'height 1s',
              transitionDelay: '0.5',
            }}
          >
            <Section_1
              page={page}
              button_color={event_data.button_color}
              is_en={event_data.is_en}
              set_is_dark_color={set_is_dark_color}
              is_dark_color={is_dark_color}
              set_button_color={set_button_color}
              handle_event_data={handle_event_data}
              event_name={event_data.event_name}
              date={event_data.date}
              is_name_font={is_name_font}
              is_date_font={is_date_font}
              set_is_name_font={(value) => set_is_name_font(value)}
              set_is_date_font={(value) => set_is_date_font(value)}
              fonts_array={fonts_array}
              config_data={event_data.config_data}
              set_bg_img={() => {
                set_is_set_img_bg(is_set_img_bg + 1);
              }}
            ></Section_1>

            <Section_2
              page={page}
              config_data={event_data.config_data}
              eventDate={event_data.eventDate}
              handle_event_data={handle_event_data}
            ></Section_2>

            {/* <Section_3
              page={page}
              handle_user={(id) => {
                set_user_id(id);
              }}
            ></Section_3> */}

            <Section_4
              page={page}
              event_data={event_data}
              handle_event_data={handle_event_data}
              handle_add_event={handle_add_event}
            ></Section_4>
          </section>
        ) : (
          <section
            className={'small_event_section small_screen_slide_up'}
            style={{
              height: page < 2 ? '35vh' : '50vh',
              transition: 'height 1s',
              transitionDelay: '0.5',
            }}
          >
            <Section_1
              page={page}
              button_color={event_data.button_color}
              is_en={event_data.is_en}
              set_is_dark_color={set_is_dark_color}
              is_dark_color={is_dark_color}
              set_button_color={set_button_color}
              handle_event_data={handle_event_data}
              event_name={event_data.event_name}
              date={event_data.date}
              is_name_font={is_name_font}
              is_date_font={is_date_font}
              set_is_name_font={(value) => set_is_name_font(value)}
              set_is_date_font={(value) => set_is_date_font(value)}
              fonts_array={fonts_array}
              config_data={event_data.config_data}
              set_bg_img={() => {
                set_is_set_img_bg(is_set_img_bg + 1);
              }}
            ></Section_1>

            <Section_2
              page={page}
              config_data={event_data.config_data}
              handle_event_data={handle_event_data}
            ></Section_2>

            <Section_3
              page={page}
              handle_user={(id) => {
                set_user_id(id);
              }}
              handle_terms={() => set_show_terms(true)}
            ></Section_3>

            <Section_4
              page={page}
              event_data={event_data}
              handle_event_data={handle_event_data}
              handle_add_event={handle_add_event}
            ></Section_4>
          </section>
        )}
      </section>
      <div className='small_continue_button'>
        <button
          style={{
            opacity:
              page === 3 ||
              (localStorage.getItem('user_id') === null && page === 4)
                ? '0.5'
                : '1',
            transition: 'opacity 1s',
          }}
          className='section_1_create_event_button'
          onClick={() => {
            if (
              localStorage.getItem('user_id') === null ? page < 3 : page < 3
            ) {
              if (page === 1 && !bg_img) {
                alert('אנא בחרו תמונת רקע על מנת להמשיך');
              } else if (page === 2 && !event_data.eventDate) {
                alert('אנא ביחרו את תאריך האירוע');
              } else {
                set_animation(!animation);
                set_page(page + 1);
              }
            }
          }}
        >
          המשיכו
        </button>
      </div>
      {show_terms ? (
        <div className='terms_page'>
          <TermsOfService
            handle_terms={() => set_show_terms(false)}
          ></TermsOfService>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Add_event_small;
