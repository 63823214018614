import React, { useState } from 'react';
import './Config_event.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { DatePicker, Slider, Switch } from 'antd';
import * as Iconstet from 'react-icons/fa';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { RiPPle } from 'primereact/ripple';
import { combineDates } from '../../../../Util/helpers';
const Config_event = (props) => {
  // const dateTimeString = dateInput + "T" + timeInput;

  // // Create a new Date object using the combined string
  // const dateTime = new Date(dateTimeString);
  const [per_g, set_per_g] = useState(0.85);
  const guest_marks = {
    10: {
      label: (
        <div className='photos_slider_marks'>
          <span>{'10'}</span>
          <span className='slider_mark_span'>אורחים</span>
        </div>
      ),
    },

    200: {
      label: (
        <div className='photos_slider_marks'>
          <span>{'200'}</span>
          <span className='slider_mark_span'>אורחים</span>
        </div>
      ),
    },
    400: {
      label: (
        <div className='photos_slider_marks'>
          <span>{'400'}</span>
          <span className='slider_mark_span'>אורחים</span>
        </div>
      ),
    },
    600: {
      label: (
        <div className='photos_slider_marks'>
          <span>{'600'}</span>
          <span className='slider_mark_span'>אורחים</span>
        </div>
      ),
    },
  };
  const photos_marks = {
    5: {
      label: (
        <div className='photos_slider_marks'>
          <span>5</span>
        </div>
      ),
    },
    10: {
      label: (
        <div className='photos_slider_marks'>
          <span>10</span>
          <span
            className='slider_mark_span'
            style={{ position: 'absolute', top: '-35px' }}
          >
            מומלץ
          </span>
        </div>
      ),
    },
    15: {
      label: (
        <div className='photos_slider_marks'>
          <span>15</span>
        </div>
      ),
    },
  };
  return (
    <div className='config_event_containr'>
      <section className='sliders_section'>
        <DatePicker
          placeholder={'תאריך האירוע'}
          onChange={(date) => {
            if (date) {
              console.log(date.$d);
              props.handle_event_data({ eventDate: date });
            } else {
              props.handle_event_data({ eventDate: undefined });
            }
          }}
          format={'DD/MM/YYYY'}
          style={{
            width: 'fit-content', // Example: full width
            padding: '10px',
          }}
          value={props.eventDate}
          className='bg_box'
        />

        <div className='config_container'>
          {/* <span className="config_header">כמות אורחים באירוע</span> */}
          <div className='slider_container'>
            <Slider
              marks={guest_marks}
              defaultValue={10} // Initial value of the slider
              value={props.config_data.guests}
              min={10} // Minimum value
              max={800} // Maximum value
              step={5} // Step size for each movement
              style={{ width: '100%' }} // Inline styles
              railStyle={{ backgroundColor: 'lightgray' }} // Style for the rail
              trackStyle={{ backgroundColor: '#066fa4c6' }} // Style for the track
              handleStyle={{ borderColor: '#066fa4c6' }} // Style for the handle
              onChange={(new_val) => {
                const new_per_g =
                  new_val <= 200
                    ? 0.85
                    : new_val < 400
                    ? 0.8
                    : new_val <= 600
                    ? 0.75
                    : 0.65;
                set_per_g(new_per_g);
                props.handle_event_data({
                  config_data: {
                    ...props.config_data,
                    guests: new_val,
                    price: Math.ceil(new_val * new_per_g),
                  },
                });
              }}
            />
            <div className='slider_footer'>
              <span>{per_g + ' '} ש"ח לכל אורח</span>
            </div>
            <section className='right_section'>
              <div className='how_many_container bg_box'>
                <span>כמות האורחים</span>
                <input
                  className='geust_number_input'
                  type='number'
                  value={props.config_data.guests}
                  max={800}
                  min={10}
                  onChange={(e) => {
                    let new_val = e.target.value;

                    // Temporarily allow any value
                    props.handle_event_data({
                      config_data: {
                        ...props.config_data,
                        guests: new_val,
                      },
                    });
                  }}
                  onBlur={() => {
                    let new_val = Math.min(800, props.config_data.guests);
                    new_val = Math.max(10, new_val);
                    const new_per_g =
                      new_val <= 200
                        ? 0.85
                        : new_val < 400
                        ? 0.8
                        : new_val <= 600
                        ? 0.75
                        : 0.65;
                    set_per_g(new_per_g);
                    props.handle_event_data({
                      config_data: {
                        ...props.config_data,
                        guests: new_val,
                        price: Math.ceil(new_val * new_per_g),
                      },
                    });
                  }}
                ></input>
              </div>
              <div className='total_container bg_box'>
                <span>עלות כוללת</span>
                <span>
                  {' '}
                  <Iconstet.FaShekelSign
                    style={{ fontWeight: 300, fontSize: 'var(--xxsmall)' }}
                  ></Iconstet.FaShekelSign>{' '}
                  {props.config_data.price}{' '}
                </span>
              </div>
            </section>
          </div>
        </div>
        <div className='config_container'>
          {/* <span className="config_header">כמות תמונות לכל אורח/ת</span> */}
          <div className='slider_container'>
            <Slider
              marks={photos_marks}
              defaultValue={5} // Initial value of the slider
              value={props.config_data.photos}
              min={5} // Minimum value
              max={20} // Maximum value
              step={5} // Step size for each movement
              style={{ width: '100%' }} // Inline styles
              railStyle={{ backgroundColor: 'lightgray' }} // Style for the rail
              trackStyle={{ backgroundColor: '#066fa4c6' }} // Style for the track
              handleStyle={{ borderColor: '#066fa4c6' }} // Style for the handle
              onChange={(new_val) => {
                props.handle_event_data({
                  config_data: {
                    ...props.config_data,
                    photos: new_val,
                  },
                });
              }}
            />
            <section className='right_section'>
              <div className='how_many_container bg_box'>
                <span>תמונות לאורח</span>
                <input
                  className='geust_number_input'
                  type='number'
                  value={props.config_data.photos}
                  max={20}
                  min={1}
                  onChange={(e) => {
                    let new_val = e.target.value;

                    // Temporarily allow any value
                    props.handle_event_data({
                      config_data: {
                        ...props.config_data,
                        photos: new_val,
                      },
                    });
                  }}
                  onBlur={() => {
                    let new_val = Math.min(20, props.config_data.photos);
                    new_val = Math.max(1, new_val);

                    props.handle_event_data({
                      config_data: {
                        ...props.config_data,
                        photos: new_val,
                      },
                    });
                  }}
                ></input>
              </div>
              <div className='total_container bg_box'>
                <span>ללא עלות נוספת</span>
                <span style={{ opacity: 0 }}>_</span>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <div className="config_container total_cost">
        {" "}
        <span className="config_header">סכום כולל</span>
        <div className="guests_number">
          {" "}
          <Iconstet.FaShekelSign
            style={{ fontWeight: 300, fontSize: "11px" }}
          ></Iconstet.FaShekelSign>
          {props.config_data.price}
        </div>
      </div> */}
    </div>
  );
};

export default Config_event;
