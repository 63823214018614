import React, { useEffect, useRef, useState } from 'react';
import './Edit_event.css';
import Section_1 from '../Add_Event_Page/Section_1/Section_1';
import Wait_page from '../Wait_page/Wait_page';
import { fonts_array } from '../../Fonts/fonts';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import { get_event_by_id, set_data, upload_bg_image } from '../../firebase';
import BackGround from '../Home_page/BackGround';
import Scene from '../Add_Event_Page/Phone/Scene';
import { Ripple } from 'primereact/ripple';
import Small_Phone from '../Add_Event_Page/Phone/Small_Phone';
import * as tb from 'react-icons/tb';
import * as Iconset from 'react-icons/hi2';

const Edit_event = () => {
  const [event_data, set_event_data] = useState(null);
  const [is_dark_color, set_is_dark_color] = useState(false);
  const [button_color, set_button_color] = useState('#f5cfa2');
  const [is_name_font, set_is_name_font] = useState(false);
  const [is_date_font, set_is_date_font] = useState(false);
  const [bg_img, set_bg_img] = useState(null);
  const [is_bg_changed, set_is_bg_changed] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const handle_event_data = (set_value) => {
    set_event_data((prev_data) => ({ ...prev_data, ...set_value }));
  };
  const phoneRef = useRef();
  useEffect(() => {
    get_event_by_id(location.state.event_id, (data) => {
      set_event_data(data);
      set_bg_img(data.img_url);
      const [r, g, b] = data.button_color
        .match(/\w\w/g)
        .map((hex) => parseInt(hex, 16));
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      if (brightness < 128) set_is_dark_color(true);
      else set_is_dark_color(false);
    });
  }, [location.state.event_id]);

  const handle_bg_img = (bg_img) => {
    set_is_bg_changed(is_bg_changed + 1);
    set_bg_img(bg_img);
  };

  const handle_set_event = () => {
    const updated_event_data = { ...event_data, lastModify: new Date() };
    const callback = (url) => {
      if (is_bg_changed) {
        updated_event_data.img_url = url;
      }
      set_data(
        'event',
        location.state.event_id,
        updated_event_data,
        (result) => {
          history.push({
            pathname: `/eventFiles/${event_data.id}`,
            state: { user_id: location.state.user_id },
          });
        }
      );
    };

    if (is_bg_changed > 1) {
      upload_bg_image(bg_img, 'event_image', '', 'none', callback);
    } else {
      callback(event_data.img_url);
    }
  };

  if (!event_data) {
    return <Wait_page />;
  }

  return (
    <div className='add_event_page'>
      <BackGround />
      <div
        onClick={() => {
          history.goBack();
        }}
        style={{
          position: 'fixed',
          top: '15px',
          left: '15px',
          fontSize: '35px',

          zIndex: '3',
        }}
      >
        <Iconset.HiOutlineArrowLeft className='close_icon'></Iconset.HiOutlineArrowLeft>{' '}
      </div>
      <div className='event_section_1_header'>
        <span
          className='header_span_1'
          data-aos='zoom-out'
          data-aos-delay='800'
          data-aos-once='true'
        >
          ערכו את האירוע שלכם
        </span>
        <span
          className='header_span_2'
          data-aos='zoom-out'
          data-aos-delay='1000'
          data-aos-once='true'
        >
          עצבו את האירוע הייחודי שלכם
        </span>
      </div>
      <section className='event_section_page'>
        <section
          onClick={() => {
            set_is_date_font(false);
            set_is_name_font(false);
          }}
          className='event_section'
          style={{
            fontFamily: 'OpenSans',
            height: window.innerWidth < 900 ? '45%' : '',
          }}
        >
          <Section_1
            page={1}
            button_color={event_data.button_color}
            set_is_dark_color={set_is_dark_color}
            set_button_color={set_button_color}
            is_en={event_data.is_en}
            handle_event_data={handle_event_data}
            event_name={event_data.event_name}
            date={event_data.date}
            is_name_font={is_name_font}
            is_dark_color={is_dark_color}
            is_date_font={is_date_font}
            set_is_name_font={set_is_name_font}
            set_is_date_font={set_is_date_font}
            fonts_array={fonts_array}
            config_data={event_data.config_data}
          />
        </section>

        <div
          className='phone_3d_cntainer slide_right'
          style={{ marginTop: window.innerWidth < 900 ? '-5%' : '' }}
        >
          {window.innerWidth < 900 ? (
            <Small_Phone
              ref={phoneRef}
              phoneRef={phoneRef}
              is_en={event_data.is_en}
              button_color={event_data.button_color}
              event_name={event_data.event_name}
              photos={event_data.config_data.photos}
              date={event_data.date}
              is_dark_color={is_dark_color}
              handle_bg_img={handle_bg_img}
              handle_event_data={handle_event_data}
              image_url={bg_img}
              page={1}
              // isFilePage={true}
            />
          ) : (
            <Scene
              button_color={event_data.button_color}
              event_name={event_data.event_name}
              date={event_data.date}
              is_dark_color={is_dark_color}
              is_en={event_data.is_en}
              handle_bg_img={handle_bg_img}
              handle_event_data={handle_event_data}
              image_url={bg_img}
            />
          )}
        </div>
      </section>
      <div className='continue_button'>
        <button
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
          className='section_1_create_event_button'
          onClick={handle_set_event}
        >
          שמירת השינויים
        </button>
      </div>
    </div>
  );
};

export default Edit_event;
