import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  getImagesInFolder,
  getMyImages,
  get_event_by_id,
  incrementCounter,
} from '../../firebase';
import 'swiper/css/zoom';
import BackGround from '../Home_page/BackGround';
import * as go from 'react-icons/go';
import './Gallery_page.css';
import Gallery from 'react-photo-gallery';
// import { Gallery } from "react-grid-gallery";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import * as Iconset from 'react-icons/hi2';
import * as bi from 'react-icons/bi';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import InfiniteScroll from 'react-infinite-scroller';
// import required modules
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BeatLoader } from 'react-spinners';
import { filters } from '../Camera/filters';
import Wait_page from '../Wait_page/Wait_page';
import Image_compoent from './Image_compoent';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as hi2 from 'react-icons/hi2';
import * as md from 'react-icons/md';

import { RWebShare } from 'react-web-share';
import QRCode from 'react-qr-code';
import {
  addImagesToArray,
  deleteImageFromDb,
  getImageFromDb,
} from '../../IndexDb';
import { useUpload } from '../../UploadContext';

const Gallery_page = (props) => {
  const { uploadImage, uploading } = useUpload();

  const location = useLocation();
  const { event_id } = useParams();
  const [images, set_images] = useState([]);
  const [selected_images, set_selected_images] = useState([]);

  const [my_images, set_my_images] = useState([]);
  const [toggler, setToggler] = useState(0);
  const [img_index, set_img_index] = useState(-1);
  const [is_my_photos, set_is_my_photos] = useState(false);
  const [token, set_token] = useState(null);
  const [my_token, set_my_token] = useState(null);
  const [event, set_event_data] = useState();
  const [user, set_user] = useState(-1);

  const [has_more, set_has_more] = useState(true);
  const [my_has_more, set_my_has_more] = useState(true);
  const [sub_folder, set_sub_folder] = useState(0);
  const [is_download, set_is_download] = useState(false);
  const [is_admin, set_is_admin] = useState(false);
  const [is_share, set_is_share] = useState(false);
  const [is_first_time, set_is_first_time] = useState(true);
  const [localImages, setLocalImages] = useState([]);
  const history = useHistory();

  const [dbImages, setDbImages] = useState([]);
  const [localArray, setLocalArray] = useState(
    location?.state?.localArray ? location?.state?.localArray : []
  );
  // function disable_back() {
  //   window.history.pushState(null, null, location.href);

  //   window.onpopstate = function () {
  //     window.history.go(1);
  //   };
  // }
  // function enable_back() {
  //   window.history.pushState(null, null, `/event/${event_id}`); // Restore previous state
  // }
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const checkImageExists = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch {
      return false;
    }
  };
  const filterValidImages = async (imagesArray) => {
    // Use Promise.all to wait for all image existence checks to complete
    const results = await Promise.all(
      imagesArray.map(async (image) => {
        const exists = await checkImageExists(image.src);
        return { ...image, exists };
      })
    );

    // Iterate over results and delete invalid images from IndexedDB
    await Promise.all(
      results
        .filter((image) => !image.exists)
        .map(async (image) => {
          await deleteImageFromDb(`${event_id}_images`, image.id);
        })
    );

    // Filter out invalid images
    return results.filter((image) => image.exists);
  };
  const updateImages = async () => {
    try {
      // Get images from IndexedDB
      const data = await getImageFromDb(`${event_id}_images`);
      alert(data[0]);
      let imagesArray = data;

      // Filter out invalid images
      imagesArray = await filterValidImages(imagesArray);

      // Update state with valid images
      setLocalImages(imagesArray);
      if (imagesArray.length > 0)
        set_token(imagesArray[imagesArray.length - 1].id);
      // set_has_more(true);
    } catch (error) {
      console.error('Error updating images:', error);
    }
  };
  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    // Set initial height
    updateHeaderHeight();

    // Update height on window resize
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, [event]);
  useEffect(() => {
    if (!location.state) {
      get_event_by_id(event_id, (data) => {
        set_event_data(data);
        if (data.user_id === localStorage.getItem('user_id'))
          set_is_admin(true);
      });
      const user_count = localStorage.getItem(`${event_id}_user`);

      if (!user_count) {
        incrementCounter(`${event_id}`, (count) => {
          localStorage.setItem(`${event_id}_user`, count);
          set_user(count);
        });
      } else set_user(user_count);
    } else {
      set_event_data(location.state.event);
      set_user(location.state.user);

      if (location.state?.event?.user_id === localStorage.getItem('user_id'))
        set_is_admin(true);
    }
    getImageFromDb(`${event_id}_loading`)
      .then((data) => {
        // Handle the data returned from addImageToDb
        let dbImagesArray = [];
        data.forEach((fileObj, index) => {
          const url = URL.createObjectURL(fileObj.file);

          dbImagesArray.push({
            src: url,
            width: 200,
            height: 300,
            name: fileObj.name,
            key: fileObj.name,
            id: fileObj.name,
            isLocal: true,
          });
        });
        setDbImages(dbImagesArray);
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error:', error);
      });
    // updateImages();
  }, [selected_images]);
  const urlToBlob = async (url) => {
    try {
      // Fetch the resource from the URL
      const response = await fetch(url);

      // Check if the response is ok (status in the range 200-299)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Extract the Blob from the response
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error fetching Blob from URL:', error);
      throw error;
    }
  };
  const load_data = () => {
    getImagesInFolder(
      `${event_id}`,

      token,

      (images_array) => {
        // addImagesToArray(`${event_id}_images`, images_array.items);
        set_images([...images, ...images_array.items]);

        set_token(images_array.next_token);
        if (images_array.next_token === null) set_has_more(false);
      }
    ).catch((error) => {
      set_has_more(false);
      console.log('Error fetching images:', error);
    });
  };
  const load_data_my_data = async () => {
    set_is_first_time(false);
    await getMyImages(`${event_id}`, Number(user), (images_array) => {
      set_my_images([...my_images, ...images_array.items]);
      set_my_has_more(false);
    }).catch((error) => {
      console.log('Error fetching images:', error);
      alert('something went wrong... try again');
    });
  };
  // const openLightbox = useCallback((event, { photo, index }) => {
  //   console.log(index, toggler);
  //   set_img_index(index);
  //   if (toggler === index) setToggler(-1);
  //   else setToggler(index);
  // }, []);
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);
  useEffect(() => {
    setLocalArray(props.localArray);
  }, [props.localArray]);

  // const imageRenderer = ({ index, left, top, key, photo }) => {
  //   return (
  //     <img
  //       className="renderd_image"
  //       onClick={() => {
  //         set_img_index(index);
  //       }}
  //       key={key}
  //       src={photo.src}
  //       style={{
  //         width: photo.width ? photo.width : "220px",
  //         height: photo.width && photo.height ? photo.height : "220px",
  //         filter: filters[photo.filter],
  //         margin: "2px",
  //       }}
  //     />
  //   );
  // };
  const imageRenderer = ({ index, left, top, key, photo }) => {
    return (
      <Image_compoent
        key={index}
        index={index}
        photo={photo}
        localArray={localArray}
        isLocal={photo.isLocal}
        handle_img_clicked={(i) => {
          set_img_index(i);
          // disable_back();
        }}
        handleRemoveImage={(name) => {
          deleteImageFromDb(`${event_id}_loading`, name);

          const array = dbImages.filter((file) => file.name !== name);
          setDbImages(array);
          localStorage.setItem(
            `${event_id}_left`,
            Number(localStorage.getItem(`${event_id}_left`)) + 1
          );
        }}
        handleTryAgain={async (name) => {
          props.handleSetLocalArray(name, true);
          const fileObj = dbImages.find((obj) => obj.name === name);
          urlToBlob(fileObj.src)
            .then(async (blob) => {
              const result = await uploadImage(
                blob,
                `${event_id}`,
                event_id,
                user,
                name,
                ''
              );
              if (result.success) {
                deleteImageFromDb(`${event_id}_loading`, name);

                const array = dbImages.filter((file) => file.name !== name);
                setDbImages(array);
                set_images([{ ...fileObj, isLocal: false }, ...images]);
              }
              // Do something with the Blob
            })
            .catch((error) => {
              console.error('Failed to retrieve Blob:', error);
            });
        }}
        user={user}
        isFirstTime={is_first_time}
        //change this in the future
        folder={`${event_id}`}
        filter_image={filter_image}
        event_id={event_id}
        is_download={is_download}
        handle_selected_image={(image_index, is_selected) => {
          let new_array = selected_images;
          if (is_selected) {
            set_selected_images([...selected_images, image_index]);
          } else {
            set_selected_images(
              new_array.filter((index) => index !== image_index)
            );
          }
        }}
        is_selected={selected_images.includes(index)}
        is_my={is_admin || photo.name.split('_')[1] === user}
      ></Image_compoent>
    );
  };
  const filter_image = (name) => {
    set_images(images.filter((img) => img.name !== name));
    set_my_images(my_images.filter((img) => img.name !== name));
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<div class="' +
        className +
        ` image_color_index" style="background-color:black; width: 15px; height: 15px; marginBottom:10px;"></div>`
      );
    },
  };
  async function saveImg() {
    const zip = new JSZip();
    const folder = zip.folder('Moments_' + event.event_name.name);

    await Promise.all(
      selected_images.map(async (image_index) => {
        const photo = is_my_photos
          ? my_images[image_index]
          : images[image_index];
        const name = photo.name + '.jpeg'; // Modify this to set a dynamic name if needed

        try {
          const response = await fetch(photo.src);
          const blob = await response.blob();

          folder.file(name, blob);
        } catch (error) {
          console.error('Error downloading the image:', error);
        }
      })
    );

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'Moments_' + event.event_name.name + '.zip');
    });
  }
  return (
    <div>
      <div
        onClick={() => {
          history.goBack();
        }}
        style={{
          position: 'fixed',
          top: '15px',
          left: '15px',
          fontSize: '35px',

          zIndex: '3',
        }}
      >
        <Iconset.HiOutlineArrowLeft className='close_icon'></Iconset.HiOutlineArrowLeft>{' '}
      </div>
      {event ? (
        <div className={`gallery_name ${event.event_name.font.name} `}>
          <header className='gallery_page_header' ref={headerRef}>
            <span
              className='gallery_event_name'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (is_admin) set_is_admin(!is_admin);
              }}
            >
              {event.event_name.name}
            </span>
            <span className={`gallery_event_date ${event.date.font.name} `}>
              {' '}
              {event.date.name}
            </span>
            <div className='gallery_header_footer'>
              <button
                onClick={() => set_is_my_photos(false)}
                className={
                  !is_my_photos
                    ? 'gallery_header_footer_btn  selected_header'
                    : 'gallery_header_footer_btn '
                }
                style={{ fontFamily: props.is_en ? '' : 'verale' }}
              >
                <span>{event.is_en ? 'All Photos' : 'כל התמונות'}</span>
              </button>
              <button
                onClick={() => {
                  set_is_my_photos(true);
                  if (my_has_more) {
                    load_data_my_data();
                  }
                }}
                style={{ fontFamily: props.is_en ? '' : 'verale' }}
                className={
                  is_my_photos
                    ? 'gallery_header_footer_btn selected_header'
                    : 'gallery_header_footer_btn '
                }
              >
                <span>{event.is_en ? 'My Photos' : ' התמונות שלי'}</span>
              </button>
            </div>
            <div className='download_share_container'>
              <div
                className={
                  is_admin
                    ? 'admin_download_icon_container'
                    : 'download_icon_container'
                }
                onClick={() => {
                  set_is_download(true);
                }}
              >
                <bi.BiDownload></bi.BiDownload>
                <span>הורדה</span>
              </div>
              {/* <div
                className="share_icon_container"
                onClick={() => {
                  set_is_share(true);
                }}
              >
                <hi2.HiOutlineShare></hi2.HiOutlineShare> <span>Share</span>
              </div> */}
            </div>
            {is_share ? (
              <div
                className='barcode_page'
                onClick={() => {
                  set_is_share(false);
                }}
              >
                <go.GoX className='barcode_page_x'></go.GoX>
                <div
                  data-aos='zoom-out'
                  data-aos-delay='400'
                  // className="barcode_container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  // style={{ backgroundColor: event_data.button_color }}
                >
                  {' '}
                  <RWebShare
                    data={{
                      text: `שתפו את הרגעים שלכם מהאירוע`,
                      url: `https://our-moments.web.app/gallery/${event.id}`,
                      title: 'Moments',
                    }}
                  >
                    <div
                      className='barcode_container'
                      style={{ backgroundColor: event.button_color }}
                    >
                      <QRCode
                        style={{
                          width: '45vw',
                          height: '45vw',
                          maxHeight: '45vh',
                          maxWidth: '45vh',
                        }}
                        bgColor='#FFFFFF00'
                        value={`https://our-moments.web.app/gallery/${event.id}`}
                      ></QRCode>

                      <div className='share_barcode_container'>
                        <span>SHARE</span>{' '}
                        <hi2.HiOutlineShare></hi2.HiOutlineShare>
                      </div>
                    </div>
                  </RWebShare>
                </div>
              </div>
            ) : (
              ''
            )}
            {is_download ? (
              <div className='download_image_page' onClick={(e) => {}}>
                <section
                  className='selete_image_section'
                  data-aos='zoom-out'
                  data-aos-delay='400'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <div className='download_image_box'>
                    <go.GoX
                      className='close_delete_box'
                      onClick={() => {
                        set_is_download(false);
                        set_selected_images([]);
                      }}
                    ></go.GoX>
                    <bi.BiDownload className='trash_icon'></bi.BiDownload>
                    <span className='delete_image_box_span'>הורדה </span>
                    <p>
                      בחרתם עד כה {selected_images.length} תמונות מתוך{' '}
                      {is_my_photos ? my_images.length : images.length}
                    </p>
                    <footer className='delete_image_box_footer'>
                      <button
                        className='delete_image_box_footer_cancle'
                        onClick={() => {
                          if (
                            (is_my_photos
                              ? my_images.length
                              : images.length) === selected_images.length
                          ) {
                            set_selected_images([]);
                          } else {
                            const newSelectedImages = [];
                            for (let i = 0; i < images.length; i++) {
                              newSelectedImages.push(i);
                            }
                            set_selected_images(newSelectedImages);
                          }
                        }}
                      >
                        <span>
                          {(is_my_photos ? my_images.length : images.length) ===
                          selected_images.length
                            ? 'בטל בחירה'
                            : 'בחר הכל'}
                        </span>
                      </button>
                      <button
                        className='delete_image_box_footer_delete'
                        onClick={() => {
                          saveImg();
                          set_is_download(false);
                          set_selected_images([]);
                        }}
                      >
                        <span>הורדה</span>
                      </button>
                    </footer>
                  </div>
                </section>
              </div>
            ) : (
              ''
            )}
          </header>

          <main
            className='gallery_page_main'
            style={{ marginTop: headerHeight }}
          >
            {!is_my_photos ? (
              <InfiniteScroll
                threshold={500}
                pageStart={0}
                loadMore={load_data}
                hasMore={has_more}
                loader={
                  <div className='image_loader' key={0}>
                    <BeatLoader color='var(--blue)' size='5vw' />
                  </div>
                }
              >
                {dbImages.length > 0 ||
                images.length > 0 ||
                localImages.length > 0 ? (
                  <Gallery
                    photos={[...dbImages, ...localImages, ...images]}
                    renderImage={imageRenderer}
                    direction={'row'}
                    // margin={2}
                  />
                ) : (
                  ''
                )}
              </InfiniteScroll>
            ) : !my_has_more ? (
              <Gallery
                photos={my_images}
                renderImage={imageRenderer}
                direction={'row'}
                // margin={2}
              />
            ) : (
              <div className='image_loader' key={0}>
                <BeatLoader color='var(--blue)' size='5vw' />
              </div>
            )}
          </main>
          {img_index >= 0 ? (
            <div
              className='images_slider_page'
              // data-aos="zoom-out"
              // data-aos-delay="300"
              // data-aos-once="true"
            >
              <Iconset.HiOutlineArrowLeft
                className='close_icon'
                onClick={() => {
                  set_img_index(-1);
                  // enable_back();
                }}
              ></Iconset.HiOutlineArrowLeft>
              <Swiper
                zoom={true}
                onSlideChange={(swiper) => {
                  if (!is_my_photos) {
                    if (has_more && swiper.realIndex === images.length - 3)
                      load_data();
                  }
                }}
                style={{ width: '100%', height: 'calc(100% - 100px)' }}
                spaceBetween={30}
                initialSlide={img_index}
                pagination={{
                  ...pagination,
                  dynamicBullets: true,
                  color: 'black',
                }}
                modules={[Navigation, Pagination, Zoom]}
                className='mySwiper'
              >
                {is_my_photos
                  ? my_images.map((image) => {
                      return (
                        <SwiperSlide key={image.name}>
                          <div
                            className='swiper_slider swiper-zoom-container'
                            // data-aos="zoom-out"
                            // data-aos-delay="300"
                            // data-aos-once="true"
                          >
                            <img
                              src={image.src}
                              className='swiper_div_img'
                              style={{
                                // backgroundImage: `url(${image.src})`,
                                filter: filters[image.filter],
                              }}
                            ></img>
                          </div>
                        </SwiperSlide>
                      );
                    })
                  : [...dbImages, ...localImages, ...images].map((image) => {
                      return (
                        <SwiperSlide key={image.name}>
                          <div
                            className='swiper_slider swiper-zoom-container'
                            // data-aos="zoom-out"
                            // data-aos-delay="300"
                            // data-aos-once="true"
                          >
                            <img
                              className='swiper_div_img'
                              // style={{
                              //   backgroundImage: `url(${image.src})`,
                              //   filter: filters[image.filter],
                              // }}
                              src={image.src}
                            ></img>
                          </div>
                        </SwiperSlide>
                      );
                    })}
              </Swiper>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <Wait_page></Wait_page>
      )}
    </div>
  );
};

export default Gallery_page;
