import React, { useRef, useState } from 'react';
import {
  add_data,
  handleSignInWithGoogle,
  sendPasswordReset,
  set_data,
  sign_in,
} from '../../../firebase';
import { styled } from '@mui/material';
import TermsOfService from './TermsOfService';

const Sign_in = (props) => {
  const [email, set_email] = useState('');
  const [password, set_password] = useState('');
  const [error, set_error] = useState('');
    const [confirmation, set_confirmation] = useState(false);
    const [forget, set_is_forget] = useState(false)
  const [social, set_social] = useState(false);
  const social_ref = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!confirmation) {
      set_error('נא לאשר את התקנון לפני שממשיכים');
    } else {
      if (email === '' || password === '') {
        set_error('אנא מלאו את כל הפרטים על מנת להמשיך');
        return;
      }
      try {
        sign_in({ email, password }, (result) => {
          if (result.status) {
            const user = result.user_id;

            set_data('user', user, { email: email }, () => {});
            localStorage.setItem('user_id', user);
            props.handle_user(user);
          } else {
            if (result.err.includes('weak'))
              set_error('סיסמא חלשה, נסו סיסמא עם מעל 6 תווים');
            if (result.err.includes('already'))
              set_error('כבר קיים משתמש עם מייל זה, נסו מייל אחר');
            if (result.err.includes('invalid'))
              set_error('אחד או יותר מהפרטים המוזנים שגוי');
            if (result.err.includes('already'))
              set_error('כבר קיים משתמש עם מייל זה, נסו מייל אחר');
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleChange = (e) => {
    set_confirmation(e.target.checked);
  };
  return (
      <div className='sign_in_cntainer'>
          {forget ? <><main style={{ paddingBottom:'10px', width: '60vw', maxWidth: '500px' }} className='sign_in_input_section'>
              <span>אנא הזינו את המייל שלכם ונשלח אליכם קישור לאיפוס הסיסמא</span>
              <div className='email_input_container'>
                  <input
                      type='email'
                      placeholder='הזינו מייל'
                      required
                      value={email}
                      onChange={(e) => set_email(e.target.value)}
                  ></input>
              </div>
              <button
                  className='sign_in_continue_buttton'
                  onClick={(e) => {
                      e.preventDefault()
                      sendPasswordReset(email)
                      set_is_forget(false)
                  }}
              >
                  <span>המשיכו</span>
              </button>
          </main> </> : <>
      <main>
        {/* <button
          onClick={async () => {
            if (!confirmation) {
              set_error('נא לאשר את התקנון לפני שממשיכים');
            } else {
              try {
                const result = await handleSignInWithGoogle();
                if (result) {
                  const user = result.user;
                  set_data(
                    'user',
                    user.uid,
                    { email: user.email, name: user.displayName },
                    () => {}
                  );

                  localStorage.setItem('user_id', user.uid);
                  props.handle_user(user.uid);
                }
              } catch (error) {
                console.log(error);
              }
            }
          }}
          className='google_button bg_box'
        >
          <img
            className='button_img'
            src='https://www.svgrepo.com/show/475656/google-color.svg'
            loading='lazy'
            alt='google logo'
          ></img>
          <span>Continue with Google</span>
        </button>

        <div className='or_container'>
          <div className='line_container'></div>
          <span>או</span>
          <div className='line_container'></div>
        </div> */}
        <section className='sign_in_input_section'>
          <div className='sign_in_input_container'>
            <div className='email_input_container'>
              <input
                type='email'
                placeholder='הזינו מייל'
                required
                value={email}
                onChange={(e) => set_email(e.target.value)}
              ></input>
            </div>
            <div className='email_input_container'>
              <input
                type='password'
                placeholder='הזינו סיסמא'
                required
                value={password}
                onChange={(e) => set_password(e.target.value)}
              ></input>
            </div>
          </div>
          {error !== '' ? (
            <span className='sign_in_error_message'>{error}</span>
          ) : (
            ''
          )}
        </section>
        <section className='check_container'>
          <div
            className='confi_container'
            onClick={() => {
              set_confirmation(!confirmation);
            }}
          >
            <input
              type='checkbox'
              onChange={handleChange}
              checked={confirmation}
            ></input>
            <span>
              האם אתם מסכימים{' '}
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  props.handle_terms();
                }}
                style={{ borderBottom: '1px solid black', cursor: 'pointer' }}
              >
                לתקנון
              </span>{' '}
              שלנו?
            </span>
          </div>
          <div
            className='confi_container'
            onClick={() => {
              social_ref.current.click();

              set_social(!social);
            }}
          >
            <input
              ref={social_ref}
              type='checkbox'
              onChange={() => {}}
              checked={social}
            ></input>
            <span>האם אתם מעוניינים לקבל עדכונים מאיתנו?</span>
          </div>
        </section>
      </main>
                  <footer className='sign_in_footer'>
                      <button onClick={() => set_is_forget(true)} className='forget_password'><span>שכחתי סיסמא</span></button>

        <button
          className='sign_in_continue_buttton'
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          <span>המשיכו</span>
              </button>
              </footer>
          </>}
    </div>
  );
};

export default Sign_in;
